<template>
  <div class="vip-record-list">
    <div class="go-back">
      <span @click="prev" class="prev">{{ i18n.Topbar.vip5 }}</span
      ><span>{{ i18n.Vip.Usage }}</span>
    </div>
    <div class="vip-record-list-content">
      <div class="vip-record-list-header">
        <el-tabs
          v-model="activeName"
          class="vip-record-tabs"
          @tab-click="handleTabsClick"
        >
          <el-tab-pane
            v-for="item of tabsList"
            :key="item.key"
            :label="item.name"
            :name="item.key"
          />
        </el-tabs>
      </div>
      <div class="body" v-loading="loading">
        <el-table
          v-if="activeName === 'use'"
          class="vip-record-list-table"
          :data="list"
        >
          <el-table-column prop="name" :label="i18n.Vip.theme" width="200">
          </el-table-column>
          <el-table-column prop="use_type" :label="i18n.Vip.purpose"> </el-table-column>
          <el-table-column prop="use_money" :label="i18n.Vip.Consumption" width="160">
            <template slot-scope="scope">
              {{ scope.row.use_money }}
            </template>
          </el-table-column>
          <el-table-column prop="last_money" :label="i18n.Vip.Remaining" width="160">
          </el-table-column>
          <el-table-column prop="use_time" :label="i18n.Vip.Operation" width="190">
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
        <el-table v-else class="vip-record-list-table" :data="list">
          <el-table-column prop="order_sn" :label="i18n.Vip.order" width="190">
          </el-table-column>
          <el-table-column prop="name" :label="i18n.Vip.OrderNmae"> </el-table-column>
          <el-table-column prop="money" :label="i18n.Vip.OrderAmount" width="140">
            <template slot-scope="scope"> ￥{{ scope.row.money }} </template>
          </el-table-column>
          <el-table-column prop="pay_type" :label="i18n.Vip.Payment" width="140">
          </el-table-column>
          <el-table-column prop="pay_status" :label="i18n.Vip.Transaction" width="140">
          </el-table-column>
          <el-table-column prop="pay_time" :label="i18n.Vip.Completion" width="180">
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
      <p
        class="no-more"
        v-if="!loading && list && list.length && list.length < 10"
      >
        {{i18n.Vip.No}}
      </p>
    </div>
    <el-pagination
      v-if="list && list.length && pagination.pageCount > 1"
      small
      layout="prev, pager, next"
      class="vip-record-list-pagination"
      @current-change="fetchList"
      :page-count="pagination.pageCount"
      :page-size="pagination.pageSize"
      :current-page.sync="pagination.currentPage"
    >
    </el-pagination>
  </div>
</template>

<script>
import { apiVipUseList, apiVipBuyList } from '@/api/api.js'
import Empty from '@/components/Empty'
import i18n from '@/i18n/i18n'

export default {
  components: {
    Empty,
  },
  data() {
    return {
      list: [],
      pagination: {
        pageCount: 1,
        currentPage: 1,
        pageSize: 20,
      },
      loading: false,
      tabsList: [
        {
          key: 'buy',
          name: i18n.locale === 'ch' ? '购买记录' : 'Purchase record',
        },
        {
          key: 'use',
          name: i18n.locale === 'ch' ? '点券使用' : 'Use of counting coupons',
        },
      ],
      activeName: '',
    }
  },
  computed: {
    i18n() {
      return this.$t('messages')
    },
  },
  created() {
    this.activeName = this.$route.query.type || 'buy'
  },
  watch: {
    activeName() {
      this.fetchList(1)
    },
  },
  methods: {
    handleTabsClick(type) {
      this.$router.push({ name: 'vipRecord', query: { type: this.activeName } })
    },
    prev() {
      this.$router.push({ name: 'vip' })
    },
    fetchList(page) {
      if (this.loading) {
        return
      }
      if (page) {
        this.pagination.currentPage = page
      }
      this.loading = true
      if (this.activeName === 'use') {
        apiVipUseList({
          page: this.pagination.currentPage,
          page_size: this.pagination.pageSize,
        })
          .then((res) => {
            if (res.code !== 200) {
              return
            }
            this.list = res.data.list

            this.list.map((item) => {
              if (item.last_money === '--') {
                item.last_money = 0
              } else {
                item.last_money = item.last_money - item.use_money
              }
            })

            this.pagination.pageCount = res.data.pager.page_cnt
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        apiVipBuyList({
          page: this.pagination.currentPage,
          page_size: this.pagination.pageSize,
        })
          .then((res) => {
            if (res.code !== 200) {
              return
            }
            this.list = res.data.list

            this.pagination.pageCount = res.data.pager.page_cnt
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    jump(data) {
      if (data.type == 'practice') {
        this.$router.push({
          name: 'Exercises',
          query: {
            clid: data.clid,
            practiceId: data.paramerid,
            from: 'collect',
          },
        })
      }
      if (data.type == 'gremat') {
        this.$router.push({
          name: 'Exercises',
          query: { clid: data.clid, greId: data.paramerid, from: 'collect' },
        })
      }
    },
  },
}
</script>

<style lang="scss">
.vip-record-list {
  .body {
    padding: 30px 40px;
  }
  .no-more {
    color: #d5d5d5;
    padding-top: 40px;
    text-align: center;
    font-size: 14px;
  }
  .vip-record-list-table {
    thead {
      th {
        background: #fafafa;
      }
    }
    .cell {
      line-height: 44px;
      padding-left: 27px;
    }
  }
  .vip-record-tabs {
    height: 70px;
    margin-right: 40px;
    line-height: 80px;
    .el-tabs__nav-wrap::after {
      background: transparent;
    }
    .el-tabs__active-bar {
      background-color: transparent;
      overflow: hidden;
      &::after {
        content: '';
        position: absolute;
        width: 14px;
        height: 2px;
        background: #ff606d;
        left: 50%;
        margin-left: -7px;
      }
    }
    .el-tabs__item {
      font-size: 16px;
    }
  }
  .go-back {
    color: #3b86ff;
    padding: 24px 0 20px;
    font-weight: 400;
    font-size: 14px;
    .prev {
      display: inline-block;
      cursor: pointer;
      &::after {
        content: '>';
        margin: 0 10px;
      }
    }
  }
  .vip-record-list-content {
    background: #fff;
    border-radius: 10px;
    min-height: 879px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  }
  .vip-record-list-header {
    display: flex;
    padding: 0 40px;
    height: 90px;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  }
  .vip-record-list-pagination {
    text-align: center;
    margin-top: 15px;
    &.el-pagination--small {
      .el-pager li,
      .btn-prev,
      .btn-next {
        border: 1px solid rgba(199, 202, 222, 1);
        border-radius: 3px;
        margin: 0 3px;
        color: #c7cade;
      }
      .el-pager li.active {
        border: 1px solid rgba(59, 134, 255, 1);
        color: #3b86ff;
      }
      .btn-prev,
      .btn-next {
        padding: 0 4px;
      }
      .el-pager li.btn-quicknext,
      .el-pager li.btn-quickprev {
        border: none;
      }
    }
  }
}
</style>
